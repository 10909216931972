import { toShortGermanFormat } from '../../../utils/numberFormater';
import _ from 'lodash';
import HighChartBase from '../../Charts/Base';
import React from 'react';
import useStyles from '../../../containers/CustomerDashboardV2/components_v2/charts/StressTestChart/styles';

const StressTestChartBase = ({categories, series, yAxisTitle, yAxisMax, yAxisUnits, type}) => {
  const classes = useStyles();

  return (
    <HighChartBase
      className={classes.chart}
      options={{
        chart: {
          type: 'column',
          height: 500,
          style: {
            fontFamily: '"Roboto-Regular", "Lucida Sans Unicode", Verdana, Arial, Helvetica, sans-serif',
            fontSize: 14
          },
          events: {
            render: function () {
              const minYPos = 20;
              const percentDataLabelWidth = 40;
              const offsetFromBar = 2;
              const chartYaxisAccuracy = 15;  // as chart ended after bottom line
              const chartBottomOffset = minYPos + chartYaxisAccuracy;
              const maxYPos = this.plotSizeY - chartBottomOffset;

              for (let j = 0; j < this.series.length; j++) {
                for (let i = 0; i < this.series[j].data.length; i++) {
                  const point = this.series[j].data[i];
                  if (point && point.dataLabel && point.isInside) {
                    const pointOffsetFromBar = point.y < 0 ? percentDataLabelWidth + offsetFromBar : -offsetFromBar;
                    let dataLabelYPos = point.dataLabel.alignAttr.y + pointOffsetFromBar;

                    if (dataLabelYPos > maxYPos) {
                      dataLabelYPos = maxYPos;
                    }
                    if (dataLabelYPos < minYPos) {
                      dataLabelYPos = minYPos;
                    }

                    point.dataLabel.attr({
                      y: dataLabelYPos,
                    });
                  }
                }
              }
            }
          }
        },
        credits: {
          enabled: false
        },
        title: {
          text: ''
        },
        xAxis: {
          categories: categories,
          tickLength: 0,
          gridLineWidth: 1,
          labels: {
            style: {
              fontSize: 14,
            },
            formatter: function () {
              return '<span>' + this.value.dates + '<br/>' + '(' + this.value.name + ')</span>';
            }
          },
          min: 0,
          max: null,
        },
        yAxis: [{
          opposite: false,
          max: yAxisMax,
          title: {
            text: '<b>' + yAxisTitle + '</b>',
            useHTML: true,
            rotation: -90
          },
          labels: {
            x: -2,
            formatter: function () {
              return toShortGermanFormat(this.value, '', yAxisUnits, 2, true);
            }
          },
          plotLines: [{
            color: '#747474',
            width: 2,
            value: 0,
            zIndex: 10
          }]
        }, {
          opposite: true,
          linkedTo: 0,
          title: {
            enabled: false,
          },
          max: yAxisMax,
          labels: {
            x: 2,
            formatter: function () {
              return toShortGermanFormat(this.value, '', yAxisUnits, 2, true);
            }
          },
          plotLines: [{
            color: '#747474',
            width: 2,
            value: 0,
            zIndex: 10
          }]
        }],
        legend: {
          enabled: false
        },
        tooltip: {
          enabled: false,
        },
        plotOptions: {
          column: {
            grouping: true,
            shadow: false,
            borderWidth: 0,
            stacked: 'normal',
          },
          tooltip: {
            enabled: false
          },
          series: {
            dataLabels: {
              enabled: true,
              allowOverlap: true,
              style: {
                fontWeight: 'normal',
                color: '#4D4F5C'
              },
              formatter: function () {
                return (this.y === undefined || this.y === null) ? '' : toShortGermanFormat(this.y, '', yAxisUnits, 3, true);
              },
              rotation: -90,
              y: type === 'days' ? -10 : 10,
            },
            enableMouseTracking: true
          }
        },
        navigator: {
          enabled: true,
          xAxis: {
            labels: {
              enabled: true,
              formatter: function () {
                const categories = _.get(this, 'chart.xAxis.0.categories');
                return categories[this.value].dates;
              }
            }
          },
          series: series
        },
        scrollbar: {
          enabled: true
        },
        series: series
      }} language={'DE'}/>
  );
};

export default StressTestChartBase;